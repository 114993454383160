import React, { useState, useEffect } from 'react'
import { Card, Empty, Pagination, Skeleton } from 'antd'
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons'
import { fetchKaribaEmails, fetchSendGridEmails } from '../index.services'

import EmailCard from './EmailCard'
import { EmailCardProps } from './EmailCard'
import './index.scss'

type EmailListProps = {
  emailType: string
  onEmailSelect: (email: EmailCardProps | null) => void
  isRunning?: boolean
  className?: string
}

const EmailList: React.FC<EmailListProps> = ({
  emailType,
  onEmailSelect,
  className,
  isRunning,
}) => {
  const [pagination, setPagination] = useState({ page: 1, totalItem: 0, perPage: 6 })
  const [emailList, setEmailList] = useState<EmailCardProps[] | null>(null)

  useEffect(() => {
    setEmailList(null)
    const fetchData = async () => {
      if (emailType === 'kariba') {
        const data = await fetchKaribaEmails()
        setEmailList(data)
      } else if (emailType === 'sendgrid') {
        const { emails, page, error, totalItem } = (await fetchSendGridEmails(
          pagination.page,
          pagination.perPage
        )) as any
        if (error) return
        setPagination({ ...pagination, page, totalItem })
        setEmailList(emails)
      }
    }
    fetchData()
  }, [emailType, pagination.page, pagination.perPage])

  const renderSkeleten = () => {
    return (
      <>
        <Card
          className="EmailCardComponent"
          actions={[
            <SettingOutlined key="setting" disabled />,
            <EditOutlined key="edit" />,
            <EllipsisOutlined key="ellipsis" disabled />,
          ]}
        >
          <Skeleton loading active>
            <Card.Meta></Card.Meta>
          </Skeleton>
        </Card>
        <Card
          className="EmailCardComponent"
          actions={[
            <SettingOutlined key="setting" disabled />,
            <EditOutlined key="edit" />,
            <EllipsisOutlined key="ellipsis" disabled />,
          ]}
        >
          <Skeleton loading active>
            <Card.Meta></Card.Meta>
          </Skeleton>
        </Card>
        <Card
          className="EmailCardComponent"
          actions={[
            <SettingOutlined key="setting" disabled />,
            <EditOutlined key="edit" />,
            <EllipsisOutlined key="ellipsis" disabled />,
          ]}
        >
          <Skeleton loading active>
            <Card.Meta></Card.Meta>
          </Skeleton>
        </Card>
      </>
    )
  }

  const renderContent = () => {
    if (!emailList) return renderSkeleten()
    else if (!emailList.length) return <Empty description={false}></Empty>
    return emailList.map(({ id, title, image }) => (
      <EmailCard
        key={id}
        className="border"
        id={id}
        title={title}
        image={image}
        onClick={onEmailSelect}
        emailType={emailType}
      ></EmailCard>
    ))
  }

  return (
    <div className={`EmailListComponent mt-4 ${className}`}>
      <div className="email-cards-wrapper">{renderContent()}</div>
      <div className="pagination-wrapper">
        <Pagination
          total={pagination.totalItem}
          pageSize={pagination.perPage}
          current={pagination.page}
          onChange={(page) => setPagination({ ...pagination, page })}
        ></Pagination>
      </div>
    </div>
  )
}

export default EmailList

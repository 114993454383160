import React, { useState, useEffect } from 'react'
import { Modal, Skeleton } from 'antd'
import { webPushSchema } from 'Validation'
import { connect } from 'react-redux'
import Axios from 'Utils/axios'
import Header from 'Components/CJHeader'
import CreativeContent from 'Containers/CreativeContent'
import Preview from 'Components/CreativeContentsPreview'

import { CurrentType } from 'CustomerJourneyModule'

import './index.scss'
import { CJModalPopup } from 'Components/CJNode'

const initialValues = {
  dynamic_link: 'none',
  image: '',
  title: 'Lorem ipsum dolor sit amet',
  link: '',
  body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
  utm_campaign: '',
  utm_medium: '',
  utm_source: '',
}

interface Props {
  isPopoverVisible: boolean
  value: object
  initData: any
  isRunning: boolean
  current: CurrentType
  setIsPopoverVisible: React.Dispatch<React.SetStateAction<boolean>>
  onAddNewNode: (type: string, values: ValueWebPush) => void
  onUpdateNode: (values: ValueWebPush) => void
  setCreativeContentsState: React.Dispatch<React.SetStateAction<any>>
  setPreviewData: React.Dispatch<React.SetStateAction<any>>
  setCurrent: React.Dispatch<React.SetStateAction<CurrentType>>
}

interface ValueWebPush {
  actions: {
    id: number
    title: string
    type: 'static_url' | 'dynamic_link'
    url?: string
    subtype?: string
  }
  body: string
  dynamic_link: string
  image: string
  link: string
  logo: string
  title: string
  utm_campaign: string
  utm_medium: string
  utm_source: string
}

const WebPush: React.FC<Props> = (props) => {
  const {
    setIsPopoverVisible,
    isPopoverVisible,
    value,
    onAddNewNode,
    onUpdateNode,
    setCreativeContentsState,
    initData,
    setPreviewData,
  } = props
  const [isModalVisible, setIsModalVisible] = useState(true)
  const [fields, setFields] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const CJPopupModal = React.useContext(CJModalPopup)

  const onCloseModal = () => {
    // setIsModalVisible(false)
    CJPopupModal.modalVisibleOff()
  }

  const onSubmit = async (values: ValueWebPush) => {
    onAddNewNode && (await onAddNewNode('web_push', values))
    onUpdateNode && (await onUpdateNode(values))
    // setIsModalVisible(false)
    CJPopupModal.modalVisibleOff()
  }

  useEffect(() => {
    setCreativeContentsState({ key: 'previewData', value: value || initialValues })
    setIsPopoverVisible(false)
    // setIsModalVisible(true)
    CJPopupModal.modalVisibleOn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopoverVisible])

  useEffect(() => {
    const handleFetchSchemas = async () => {
      setIsLoading(true)
      const resp = await Axios.get('/placements/web_push')
      setFields(resp.data?.schemas)
      setIsLoading(false)
    }
    handleFetchSchemas()
  }, [])

  return (
    <Modal
      {...props}
      onOk={onCloseModal}
      // onCancel={() => setIsModalVisible(false)}
      onCancel={() => CJPopupModal.modalVisibleOff()}
      visible={CJPopupModal?.isModalVisible}
      maskClosable
      title={<Header {...props} />}
      footer={[]}
      width={800}
      closable={false}
      className="ChannelsModal"
    >
      <div className={`WebPushComponent ${props.isRunning ? 'is-running' : ''}`}>
        <Skeleton loading={isLoading} active>
          <CreativeContent
            onSubmit={onSubmit}
            submitText="Save"
            initValues={initData}
            validationSchema={webPushSchema}
            onChange={(name: string, value: ValueWebPush) =>
              setPreviewData({
                key: name,
                value: value,
              })
            }
            fields={fields}
          />
          <Preview isPreview={true} type={'web_push'}></Preview>
        </Skeleton>
      </div>
    </Modal>
  )
}

interface StateProps {
  campaign: any
}

export default connect(
  (state: StateProps) => ({
    initData: state.campaign.creativeContents.previewData,
  }),
  (dispatch: any) => ({
    setCreativeContentsState: dispatch.campaign.creativeContents.setState,
    setPreviewData: dispatch.campaign.creativeContents.setPreviewData,
  })
)(WebPush)

import React, { useState, useEffect } from 'react'
import { Modal } from 'antd'
import * as yup from 'yup'
import { connect } from 'react-redux'
import { useStoreActions } from 'easy-peasy'

import Header from 'Components/CJHeader'
import CreativeContent from 'Containers/CreativeContent'
import Preview from 'Components/CreativeContentsPreview'
import { CurrentType } from 'CustomerJourneyModule'

import './index.scss'
import { CJModalPopup } from 'Components/CJNode'

export const webPushSchema = yup.object().shape({
  message_content: yup.string().max(160, 'No more than 160 characters'),
})

const initialValues = {
  message_content: '',
}

// const platforms = [
//   { label: 'Android', value: 'android' },
//   { label: 'iOS', value: 'ios' },
// ]

interface ValuesSMS {
  message_content: string
}

interface Props {
  isPopoverVisible: boolean
  value: ValuesSMS
  initData: any
  current: CurrentType
  isRunning: boolean
  onAddNewNode: (type: string, values: ValuesSMS) => void
  onUpdateNode: (values: ValuesSMS) => void
  setIsPopoverVisible: React.Dispatch<React.SetStateAction<boolean>>
  setCreativeContentsState: React.Dispatch<React.SetStateAction<any>>
  setCurrent: React.Dispatch<React.SetStateAction<CurrentType>>
}

const SMS: React.FC<Props> = (props) => {
  const {
    setIsPopoverVisible,
    isPopoverVisible,
    value,
    initData,
    onAddNewNode,
    onUpdateNode,
    setCreativeContentsState,
  } = props
  const [isModalVisible, setIsModalVisible] = useState<boolean>(true)
  const [availableChars, setAvailableChars] = useState<number>(160)
  const CJPopupModal = React.useContext(CJModalPopup)

  const { setPreviewData } = useStoreActions((action: any) => action.campaign.creativeContents)

  const onCloseModal = () => {
    // setIsModalVisible(false)
    CJPopupModal?.modalVisibleOff()
  }

  const onSubmit = async (values: ValuesSMS) => {
    onAddNewNode && (await onAddNewNode('sms', values))
    onUpdateNode && (await onUpdateNode(values))
    // setIsModalVisible(false)
    CJPopupModal?.modalVisibleOff()
  }

  useEffect(() => {
    setAvailableChars(160 - initData.message_content?.length)
  }, [initData])

  useEffect(() => {
    setCreativeContentsState({ key: 'previewData', value: value || initialValues })
    setIsPopoverVisible(false)
    // setIsModalVisible(true)
    CJPopupModal?.modalVisibleOn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopoverVisible])

  const schemas = [
    {
      name: 'message_content',
      type: 'text_area',
      label: 'Content',
      prefix: (
        <span style={{ color: availableChars < 0 ? 'red' : 'none' }}>
          Available characters: {availableChars}/160
        </span>
      ),
    },
  ]

  return (
    <Modal
      {...props}
      onOk={onCloseModal}
      onCancel={() => CJPopupModal?.modalVisibleOff()}
      visible={CJPopupModal.isModalVisible}
      maskClosable
      title={<Header {...props} />}
      footer={[]}
      width={900}
      closable={false}
      className="ChannelsModal"
    >
      <div className={`SMSComponent ${props.isRunning ? 'is-running' : ''}`}>
        <div className="SMSInput">
          <CreativeContent
            onSubmit={onSubmit}
            submitText="Save"
            initValues={value || initialValues}
            validationSchema={webPushSchema}
            fields={schemas}
            onChange={(name: string, value: string) =>
              setPreviewData({
                key: name,
                value: value,
              })
            }
          />
        </div>
        <Preview isPreview={true} type={'sms'}></Preview>
      </div>
    </Modal>
  )
}

interface StateProps {
  campaign: any
}

export default connect(
  (state: StateProps) => ({
    initData: state.campaign.creativeContents.previewData,
  }),
  (dispatch: any) => ({
    setCreativeContentsState: dispatch.campaign.creativeContents.setState,
  })
)(SMS)

import { useStoreActions, useStoreState } from 'easy-peasy'
import React, { useEffect, useState } from 'react'
import { getZnsTemplateById } from 'Services/campaigns'
import KFMLogo from 'Assets/images/LogoKFMPreview.png'
import { Button, Modal, Skeleton, Tooltip } from 'antd'
import { RiPhoneLine } from 'react-icons/ri'
import * as yup from 'yup'
import { AppPushParams } from 'Constants/ChannelSchemas/AppPush.schema'
import { CurrentType } from 'CustomerJourneyModule'
import Header from 'Components/CJHeader'
import { connect } from 'react-redux'
import FormItemZNS from './FormItemZNS'
import axios from 'Utils/cj-axios'
import TestOnDevice from 'Components/PreviewChannels/Preview/TestOnDevice'
import { getZnsTemplateByIdNew } from 'Services/new-cj'
import ZnsByTemplateId from 'Components/PreviewChannels/Preview/ZNSByTemplateId/ZnsByTemplateId'
import DynamicParams from 'Containers/Campaign/MainFlow/Components/Body/Content/Form/DynamicParams'
import personalize from 'Assets/images/personalized.png'
import useFetchParams from '../useFetchParams'
import {
  addDynamicParams,
  findAllNodesOfType,
  findNodePosition,
  formatData,
  getAllParentNodes,
  splitValues,
} from '../helpers'
import { useKeyboardShortcut } from 'Containers/Campaign/MainFlow/Components/Body/Content/useKeyboardShortcut'
import ChannelSchemas from 'Constants/ChannelSchemas'
import { findNodesByTypeFromEntrance } from '../AppPush/constants'

interface Props {
  isPopoverVisible: boolean
  value: any
  initData: any
  current: CurrentType
  isRunning: boolean
  onAddNewNode: (type: string, values: any) => void
  onUpdateNode: (values: any) => void
  setIsPopoverVisible: React.Dispatch<React.SetStateAction<boolean>>
  setCreativeContentsState: React.Dispatch<React.SetStateAction<any>>
  setCurrent: React.Dispatch<React.SetStateAction<CurrentType>>
  parentNode?: any
}
const ZaloGroup: React.FC<Props> = (props) => {
  const {
    setIsPopoverVisible,
    isPopoverVisible,
    value,
    initData,
    onAddNewNode,
    onUpdateNode,
    setCreativeContentsState,
  } = props
  const { content, toggleDynamicParam } = useStoreState((state) => state.campaignModule)
  const { setState } = useStoreActions((actions: any) => actions.campaignModule)
  const [templateId, setTemplateId] = useState<any[]>()
  const [loadingTemplate, setLoadingTemplate] = useState(false)
  const [selectedTemplateId, setSelectedTemplateId] = useState<any>()
  const [audienceId, setAudienceId] = useState()
  const [dynamicFields, setDynamicFields] = useState<any>([])
  const [interactions, setInteractions] = useState<any>([])
  const [segments, setSegments] = useState<any>([])

  const [isModalVisible, setIsModalVisible] = useState<boolean>(true)

  const { setPreviewData } = useStoreActions((action: any) => action.campaign.creativeContents)
  const { CJTree } = useStoreState((states) => states.customerJourney)
  function findNodeByType(tree, targetType) {
    if (!tree) {
      return null // If the tree is null or undefined, return null
    }

    // Check if the current node matches the target nodeType
    if (tree.nodeType === targetType) {
      return tree
    }

    // If the node has children, search through each child recursively
    if (tree?.parentNode) {
      const result = findNodeByType(tree?.parentNode, targetType)
      if (result) {
        return result // Return the found node if it matches
      }
    }

    // Return null if the node is not found
    return null
  }

  useEffect(() => {
    setCreativeContentsState({ key: 'previewData', value: value })
    setIsPopoverVisible(false)
    setIsModalVisible(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopoverVisible])

  const onCloseModal = () => {
    setIsModalVisible(false)
  }

  const handleTest = (payload) => {
    try {
      axios.post('/tests/campaigns/send-notification', {
        ...payload,
        content: {
          ...payload.content,
          params: payload.content.params.reduce((acc, item) => {
            acc[item.name] = item.key
            return acc
          }, {}),
        },
      })
    } catch (err) {
      console.log(err)
    }
  }

  const onSubmit = async (values: any) => {
    onAddNewNode && (await onAddNewNode('znsByTemplateId', values))
    onUpdateNode && (await onUpdateNode(values))
    setIsModalVisible(false)
  }

  useEffect(() => {
    const fetchInteractions = async () => {
      const resp = await axios.get('/customer-journeys/interactions')
      setInteractions(resp.data)
    }

    const fetchSegments = async () => {
      const resp = await axios.get('/customer-journeys/attributes')
      setSegments(resp.data)
    }
    fetchInteractions()
    fetchSegments()
  }, [])

  useEffect(() => {
    const getTemplate = async () => {
      setLoadingTemplate(true)
      try {
        const resp = await getZnsTemplateByIdNew()
        setTemplateId(resp?.data?.templates)
      } catch (error) {
        setLoadingTemplate(false)
        console.log('something went wrong!')
        setTemplateId([])
      }
      setLoadingTemplate(false)
    }
    getTemplate()
  }, [templateId?.length])
  useEffect(() => {
    const selectedTemp = templateId?.filter((i) => i.template_id === content?.template_id)
    if (selectedTemp?.length) {
      setSelectedTemplateId({ ...selectedTemp[0] })
    }
  }, [content])
  useEffect(() => {
    setDynamicFields(
      addDynamicParams(
        ChannelSchemas['ol_app'],
        formatData(
          findNodesByTypeFromEntrance(
            CJTree,
            (props?.current?.nodeUuid as any) ?? props?.parentNode?.childrenNodes?.[0]?.nodeUuid,
            ['waitIn', 'aiCheck', 'condition', 'checkUserAttr']
          )
            ?.map((item: any) => {
              return {
                value: item?.value,
                alias: findNodePosition(CJTree, item?.nodeUuid),
                nodeType: item?.nodeType,
                attributeCode: item?.value?.interaction,
              }
            })
            ?.map((i: any) => {
              if (i?.nodeType === 'waitIn') {
                const typesToMatch = i?.value?.conditions
                  ?.flatMap((condition) => condition.dimension.segments)
                  ?.map((item) => item.type)
                return {
                  ...i,
                  value: interactions?.filter((item: any) => typesToMatch?.includes(item?.code)),
                }
              } else {
                const typesToMatch = i?.value?.segments?.map((item) => item.type)
                return {
                  ...i,
                  value: segments?.filter((item) => typesToMatch?.includes(item?.code)),
                }
              }
            })
        )?.concat(
          [
            'condition',
            'waitIn',
            'wait',
            'split',
            'promotion',
            'coupon',
            'outOfPromotion',
          ]?.includes(props?.parentNode?.nodeType)
            ? []
            : findNodesByTypeFromEntrance(
                CJTree,
                (props?.current?.nodeUuid as any) ||
                  props?.parentNode?.childrenNodes?.[0]?.nodeUuid,
                ['aiCheck']
              )?.flatMap((obj) => {
                return obj?.value?.params?.map((param) => ({
                  ...param,
                  parameter_code: `{{${obj?.alias}--${param?.parameter_code}}}`,
                }))
              })
        )
      )
    )
  }, [CJTree, props?.current?.nodeUuid, interactions, segments, props])
  useEffect(() => {
    setAudienceId(
      findNodeByType(props, 'checkAudienceSegment')?.value?.audience_segment_id ||
        CJTree?.childrenNodes?.[0]?.value?.audience_segment_id
    )
  }, [props, CJTree])

  useKeyboardShortcut({
    keyCombo: 'Meta+j' || 'Ctrl+j',
    onKeyPressed: () => setState({ key: 'toggleDynamicParam', value: !toggleDynamicParam }),
  })
  if (loadingTemplate)
    return (
      <div style={{ width: '400px' }}>
        <Skeleton active></Skeleton>
      </div>
    )
  return (
    <Modal
      {...props}
      onOk={onCloseModal}
      onCancel={() => setIsModalVisible(false)}
      visible={isModalVisible}
      maskClosable
      title={<Header {...props} />}
      footer={null}
      width={1100}
      closable={false}
      className="ChannelsModal"
    >
      <div className="w-full flex gap-4 relative" id="CampaignContent">
        <div className="flex-1 bg-gray_1 p-4 rounded overflow-scroll max-h-[70vh]">
          <FormItemZNS onSubmit={onSubmit} audience_id={audienceId} value={value} />
        </div>
        <div className="flex-1 gap-4 bg-gray_1 p-4 rounded border mx-auto h-fit flex justify-center">
          <div className="w-full" id="ContentPreview">
            <div className="flex justify-between items-center">
              <span className="font-semibold text-lg">Preview</span>
              {/* <div className="platform-select text-brand_primary font-semibold">
            <Select options={platforms} onChange={(value) => setPlatform(value)} value={platform} />
          </div> */}
              <TestOnDevice
                onSubmit={(payload) => {
                  handleTest(payload)
                }}
              />
            </div>
            <div className="preview-screen">
              <div>{<ZnsByTemplateId />}</div>
            </div>
          </div>
        </div>
        <div>
          {toggleDynamicParam && (
            <div
              className=" bg-white border rounded-lg transition-all duration-500"
              style={{ width: '300px' }}
            >
              <DynamicParams
                extraParams={[
                  {
                    group_code: 'ai_params',
                    group_name: 'AI Params',
                    parameters: splitValues(
                      dynamicFields?.find((item: any) => item?.type === 'dynamic_content')
                        ?.dynamicParams
                    )?.aiValues?.map((i: any) => {
                      return {
                        parameter_code: i?.parameter_code?.match(/\{\{(.+?)\}\}/)?.[1],
                        format: i?.format?.map((item: any) => {
                          return {
                            ...item,
                            format_key: item?.format_key,
                            format_value: item?.format_value,
                          }
                        }),
                      }
                    }),
                  },
                  {
                    group_code: 'dimension_params',
                    group_name: 'Dimension Params',
                    parameters: splitValues(
                      dynamicFields?.find((item: any) => item?.type === 'dynamic_content')
                        ?.dynamicParams
                    )?.otherValues?.map((i: any) => {
                      return {
                        parameter_code: i?.parameter_code?.match(/\{\{(.+?)\}\}/)?.[1],
                        format: i?.format?.map((item: any) => {
                          return {
                            ...item,
                            format_key: item?.format_key,
                            format_value: item?.format_value,
                          }
                        }),
                      }
                    }),
                  },
                  {
                    group_code: 'entrance_params',
                    group_name: 'Entrance Params',
                    parameters: findNodesByTypeFromEntrance(
                      CJTree,
                      (props?.current?.nodeUuid as any) ||
                        props?.parentNode?.childrenNodes?.[0]?.nodeUuid,
                      ['entrance']
                    )?.[0]?.value?.params?.map((item: any) => {
                      return {
                        ...item,
                        parameter_code: `1--${item?.parameter_code}`,
                      }
                    }),
                  },
                ]}
              />
            </div>
          )}
          {!toggleDynamicParam && (
            <Tooltip
              title={
                <div className="flex gap-1 flex-col w-max">
                  <div className=" font-semibold text-sm">Personalize</div>
                  <div className=" text-sm">Add personalized parameters to your message</div>
                  <div className=" text-sm text-gray-300">Cmd + J (Mac) / Ctrl + J (Window)</div>
                </div>
              }
              overlayStyle={{ width: 'max-content' }}
              overlayInnerStyle={{ width: 'max-content' }}
              overlayClassName="w-max PersonalizeButtonTooltip"
              placement="top"
            >
              <div
                style={{ position: 'absolute', bottom: 0, right: 0 }}
                className="PersonalizeButton rounded-full cursor-pointer"
                onClick={() => setState({ key: 'toggleDynamicParam', value: !toggleDynamicParam })}
              >
                <img src={personalize} alt="personalize" />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </Modal>
  )
}

interface StateProps {
  campaign: any
}

export default connect(
  (state: StateProps) => ({
    initData: state.campaign.creativeContents.previewData,
  }),
  (dispatch: any) => ({
    setCreativeContentsState: dispatch.campaign.creativeContents.setState,
  })
)(ZaloGroup)

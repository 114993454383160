import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import imagePlaceholder from 'Assets/images/image_placeholder.png'
import { template } from './JunoTemplateString'

const JunoTemplate = ({ content, previewData }) => {
  return (
    <div
      className="ml-6 JunoTemplateComponent overflow-scroll"
      // dangerouslySetInnerHTML={{ __html: template(itemImages, imagePlaceholder) }}
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    ></div>
  )
}

export default connect(
  (state: any) => ({
    previewData: state.campaign.creativeContents.previewData,
  }),
  (dispatch: any) => ({
    setCreativeContentsState: dispatch.campaign.creativeContents.setState,
  })
)(JunoTemplate)
